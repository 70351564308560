import request from '../../libs/axios'
import fileRequest from '../../libs/axios-file'

export function getCountryVisas(params){
  return request({
    url: '/api/country-visas',
    method: 'get',
    params,
  })
}

export function storeNewCountryVisa(data) {
    return request({
        url: '/api/country-visas',
        method: 'post',
        data,
    })
}

export function showCountryVisa(id) {
    return request({
        url: '/api/country-visas/'+id,
        method: 'get',
    })
}

export function editCountryVisa(data, id) {
    return request({
        url: '/api/country-visas/'+id,
        method: 'put',
        data,
    })
}

export function deleteCountryVisa(id) {
    return request({
        url: '/api/country-visas/'+id,
        method: 'delete',
    })
}

export function getVisaTypes(params) {
    return request({
        url: '/api/visa-types',
        method: 'get',
        params,
    })
}

export function storeVisaType(data) {
    return request({
        url: '/api/visa-types',
        method: 'post',
        data,
    })
}

export function deleteVisaType(id) {
    return request({
        url: `/api/visa-types/${id}`,
        method: 'delete',
    })
}

export function deleteVisaTypeEntry(id) {
    return request({
        url: `/api/visa-type-entry/${id}`,
        method: 'delete',
    })
}

export function deleteConsularProcessingTime(id) {
    return request({
        url: `/api/consular-processing-time/${id}`,
        method: 'delete',
    })
}

export function getVisaTypeEntries(params) {
    return request({
        url: '/api/visa-type-entry',
        method: 'get',
        params,
    })
}

export function storeVisaTypeEntries(data) {
    return request({
        url: '/api/visa-type-entry',
        method: 'post',
        data,
    })
}

export function getConsularProcessing(params) {
    return request({
        url: '/api/consular-processing-time',
        method: 'get',
        params,
    })
}

export function storeConsularProcessing(data) {
    return request({
        url: '/api/consular-processing-time',
        method: 'post',
        data,
    })
}

export function storeVisaCustomField(data) {
    return request({
        url: '/api/country-visa-custom-field',
        method: 'post',
        data,
    })
}

export function deleteVisaCustomField(id) {
    return request({
        url: `/api/country-visa-custom-field/${id}`,
        method: 'delete',
    })
}

export function sortCountryVisas(data) {
    return request({
        url: '/api/sort-country-visas',
        method: 'post',
        data,
    })
}